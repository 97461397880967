import React from 'react'
import _ from 'lodash'
import {
    Field,
    getFormValues,
    initialize,
    touch,
    reduxForm
} from 'redux-form'
import Survey from '../../../../components/Form/SurveyField'
import {getLanguage} from "../../selectors";
import {connect} from "react-redux";
import getFormValidations from "./formValidations";

export const SURVEY_FORM = 'surveyForm'

const getValue = (field) => {
    let value = undefined
    switch(field.type){
        case 'labeledText':
        case 'boundedInteger':
        case 'boundedNumber':
        case 'boundedIntegerInDropdown':
        case 'labeledCheckbox':
        case 'cursor':
        case 'multiCheckbox':
        case 'rankOptions':
            value =  field.value
            break
        case 'radioButton':
            value = _.get(field, 'value.id')
            break;
    }
    return value
}


const validationFunctions = [
    (values, allValues, props) => {
        const errors = values && Object.keys(values)
            .filter(fieldId => {
                const field = values[fieldId]
                if(!field.dependentOnQuestion) return true
                const parentQuestion = values[field.dependentOnQuestion]
                const responseElementsIds = field.dependantOnResponseElement.map(elem => elem.id)
                return parentQuestion.type === 'multiCheckbox'
                    ? getValue(parentQuestion)?.some(value => responseElementsIds.includes(value.id))
                    : responseElementsIds.includes(getValue(parentQuestion))
            })
            .reduce((acc, fieldId) => {
                const field = values[fieldId]

                const validations = field ? getFormValidations(field) : []
                validations.forEach(validation => {
                    const result = validation(getValue(field), {}, {t: props.t})
                    if(result) acc[fieldId] = result
                })

                return acc

            }, {})

        if(!_.isEmpty(errors)) {
            const errorsTable = _.values(errors)

            const containsRequiredQuestion = errorsTable.some(error => error === 'required')

            return containsRequiredQuestion
                ? "Veuillez répondre à toutes les questions suivies d'un astérisque"
                : 'Formulaire invalide'
        }
        return undefined
    }
]

const SurveyWithReduxForm = reduxForm({
    form: SURVEY_FORM,
    initialValues: {},
    validate: (values, props) => {
        if(!props.initialized && !props.anyTouched && _.isArray(props.fields)) return {}
        return Object.keys(values).reduce((acc, fieldId) => {
            const field = props.fields[fieldId]

            const validations = field ? getFormValidations(field) : []
            validations.forEach(validation => {
                const result = validation(values[fieldId], {}, {t: props.t})
                if(result) acc[fieldId] = result
            })

            return acc

        }, {})
    }
})(Survey)

// binding for redux-form
const VisualComponent = ({input: {value, onChange}, meta: {touched}, language, initialize, formValues, disabled, t, ...props}) => {
    const filteredFields = Object.keys(value).filter(fieldId => {
        const field = value[fieldId]
        if(!field.dependentOnQuestion) return true
        console.log('field', field)
        const parentQuestion = value[field.dependentOnQuestion]
        const responseElementsIds = field.dependantOnResponseElement.map(elem => elem.id)
        console.log('field', field)
        console.log('response elements', responseElementsIds)
        console.log('parent value', getValue(parentQuestion))
        const condition = parentQuestion.type === 'multiCheckbox'
            ? getValue(parentQuestion)?.some(value => responseElementsIds.includes(value.id))
            : responseElementsIds.includes(getValue(parentQuestion))
        console.log('condition', condition)
        return condition
    })

    return (
        <SurveyWithReduxForm
            fields={value}
            filteredAndOrderedFieldIds={filteredFields}
            disabled={disabled}
            formValidated={touched}
            onChange={ () => {
                const defaultObject = Object
                    .keys(formValues)
                    .reduce((acc, keyField) => {
                        acc[keyField] = {
                            ...value[keyField], value: formValues[keyField]
                        }
                        return acc
                    }, {})
                onChange(defaultObject)
            }}
            initialize={initialize}
            language={language}
            t={t}
            {...props}
        />
    )
}

const FormSurveyField = ({ field, t, language, onChange, initialize, formValues, parentFormValues, ...props}) => {
    return (
        <Field
            name={field.path}
            validate={validationFunctions}
            component={VisualComponent}
            onChange={onChange}
            initialize={initialize}
            disabled={field.disabled}
            formValues={formValues}
            t={t}
            language={language}
            {...props}
        />
    )
}

const mapStateToProps = (state) => ({
    formValues: getFormValues(SURVEY_FORM)(state),
    language: getLanguage(state)
})

const mapDispatchToProps = (dispatch, { field }) => ({
    initialize: object => dispatch(initialize(SURVEY_FORM, object)),
    touch: fields => dispatch(touch(SURVEY_FORM, fields))
})

export default connect(mapStateToProps, mapDispatchToProps)(FormSurveyField)
